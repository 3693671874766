import { useEffect, useRef, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import PasswordStrengthBar from 'react-password-strength-bar';
import { toast } from 'react-toastify';
import { ZodType, z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@ariakit/react';
import Login from './Login';

type FormData = {
    username: string;
    password: string;
    confirmPassword: string;
};

const RegisterForm = () => {
    const errRef: any = useRef();
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState<boolean>(true);
    const [showConfirmPassword, setConfirmShowPassword] = useState(false);
    const [btnRegisterDisabled, setBtnRegisterDisabled] =
        useState<boolean>(false);
    const { REACT_APP_AUTH } = process.env;
    const urlRegister = `${REACT_APP_AUTH}/register`;

    const schema: ZodType<FormData> = z
        .object({
            username: z
                .string()
                .min(5, 'Username must contain at least 5  character(s)')
                .max(250)
                .email('Please enter a valid email address'),
            password: z
                .string()
                .regex(
                    new RegExp(
                        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
                    ),
                    'Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:'
                )
                .max(20, "Password can't be more then 20 characters"),
            confirmPassword: z.string(),
        })
        .refine((data) => data.password === data.confirmPassword, {
            message: 'Passwords do not match',
            path: ['confirmPassword'],
        });

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, confirmPwd]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: zodResolver(schema),
    });

    const handleSave = async () => {
        setBtnRegisterDisabled(true);

        try {
            const response = await fetch(urlRegister, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    username: user,
                    password: pwd,
                }),
            });

            if (response.status !== 200) {
                setErrMsg(
                    'Registration Failed - Invalid Details or User Already Exists'
                );
                setBtnRegisterDisabled(false);
            } else {
                toast.success(
                    'Success.Please check your email to validate your account!',
                    { autoClose: 10000 }
                );

                setUser('');
                setPwd('');
                setConfirmPwd('');
            }
        } catch (err) {
            setErrMsg('Registration Failed, please try again.');
            setBtnRegisterDisabled(false);
        }
    };

    return (
        <>
            {!showRegisterForm && <Login />}

            {showRegisterForm && (
                <div className="card">
                    <p
                        ref={errRef}
                        className={errMsg ? 'errmsg' : 'offscreen'}
                        aria-live="assertive"
                    >
                        {errMsg}
                    </p>
                    <h2>Register</h2>
                    <form onSubmit={handleSubmit(handleSave)}>
                        {errors && errors.username && (
                            <span style={{ color: 'red' }}>
                                {errors.username.message}
                            </span>
                        )}

                        <input
                            {...register('username')}
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Username"
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                        />

                        <div style={{ position: 'relative' }}>
                            {errors && errors.password && (
                                <span style={{ color: 'red' }}>
                                    {errors.password.message}
                                </span>
                            )}
                            <input
                                {...register('password')}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                name="password"
                                placeholder="Password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                style={{ paddingRight: '4.2rem' }} // Add padding to accommodate the eye icon
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                style={{
                                    position: 'absolute',
                                    top: errors.password ? '70%' : '40%',
                                    right: '0.1rem',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: 'lightgray',
                                    border: 'none',
                                }}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                        {pwd && (
                            <PasswordStrengthBar password={pwd} minLength={4} />
                        )}

                        <div style={{ position: 'relative' }}>
                            {errors && errors.confirmPassword && (
                                <span style={{ color: 'red' }}>
                                    {errors.confirmPassword.message}
                                </span>
                            )}
                            <input
                                {...register('confirmPassword')}
                                type={showConfirmPassword ? 'text' : 'password'}
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                onChange={(e) => setConfirmPwd(e.target.value)}
                                value={confirmPwd}
                                style={{ paddingRight: '4.2rem' }} // Add padding to accommodate the eye icon
                            />
                            <button
                                type="button"
                                onClick={() =>
                                    setConfirmShowPassword(!showConfirmPassword)
                                }
                                style={{
                                    position: 'absolute',
                                    top: errors.confirmPassword ? '55%' : '40%',
                                    right: '0.1rem',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: 'lightgray',
                                    border: 'none',
                                }}
                            >
                                {showConfirmPassword ? (
                                    <FaEyeSlash />
                                ) : (
                                    <FaEye />
                                )}
                            </button>
                        </div>
                        <button disabled={btnRegisterDisabled} type="submit">
                            Register
                        </button>
                    </form>
                    <p
                        style={{
                            color: 'white',
                            marginTop: '5px',
                            textAlign: 'center',
                        }}
                    >
                        Already have an account
                        <Button
                            onClick={() =>
                                setShowRegisterForm(!showRegisterForm)
                            }
                            style={{
                                backgroundColor: 'grey',
                                marginTop: '30px',
                                marginLeft: '10px',
                            }}
                        >
                            Login
                        </Button>
                    </p>
                </div>
            )}
        </>
    );
};

export default RegisterForm;
