import {
    BtnBold,
    BtnBulletList,
    BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    BtnUnderline,
    BtnUndo,
    Editor,
    EditorProvider,
    Toolbar,
} from 'react-simple-wysiwyg';
import { UserNote } from '../Models/UserNote';
import { ZodType, z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

type FormData = {
    title: string;
};

interface AddNoteProps {
    selectedNote: UserNote | null;
    handleAddNote: () => Promise<void>;
    handleUpdateNote: () => Promise<void>;
    handleCancel: () => void;
    setTitle: (title: string) => void;
    setContent: (content: string) => void;
    title: string;
    content: string;
    isButtonDisabled: boolean;
}

const AddNote: React.FC<AddNoteProps> = ({
    selectedNote,
    handleAddNote,
    handleUpdateNote,
    handleCancel,
    setTitle,
    setContent,
    title,
    content,
    isButtonDisabled,
}) => {
    const schema: ZodType<FormData> = z.object({
        title: z
            .string()
            .min(5, 'Title must contain at least 5 character(s)')
            .max(250, 'Title can not contain more then 250 character(s)'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: zodResolver(schema),
    });

    return (
        <form
            className="note-form"
            onSubmit={
                selectedNote
                    ? handleSubmit(handleUpdateNote)
                    : handleSubmit(handleAddNote)
            }
        >
            <div className="placeholder"> Title</div>
            <textarea
                {...register('title')}
                value={title}
                rows={3}
                onChange={(event) => setTitle(event.target.value)}
                style={{ marginTop: '-20px' }}
            ></textarea>
            {errors && errors.title && (
                <span style={{ color: 'red' }}>{errors.title.message}</span>
            )}
            <div className="placeholder"> Description</div>
            <EditorProvider>
                <Editor
                    value={content}
                    onChange={(event) => setContent(event.target.value)}
                >
                    <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnLink />
                        <BtnNumberedList />
                        <BtnBulletList />
                    </Toolbar>
                </Editor>
            </EditorProvider>
            {selectedNote ? (
                <div className="edit-buttons">
                    {' '}
                    <button type="submit">Save</button>
                    <button onClick={handleCancel}>Cancel</button>
                </div>
            ) : (
                <button type="submit" disabled={isButtonDisabled}>
                    Add Note
                </button>
            )}
        </form>
    );
};

export default AddNote;
