import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FaEyeSlash, FaEye } from 'react-icons/fa';
import { ZodType, z } from 'zod';
import { Button } from '@ariakit/react';
import RegisterForm from './RegisterForm';

type FormData = {
    username: string;
    password: string;
};

const Login = () => {
    const errRef: any = useRef();
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState<boolean>(false);
    const [btnLoginDisabled, setBtnLoginDisabled] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState('');
    const [cookies, setCookie] = useCookies(['user']);
    const { REACT_APP_AUTH } = process.env;
    const url = `${REACT_APP_AUTH}/login`;

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    const schema: ZodType<FormData> = z.object({
        username: z
            .string()
            .min(5, 'Username must contain at least 5 character(s)')
            .max(250)
            .email('Please enter a valid email address'),
        password: z
            .string()
            .min(5, 'Password must be minimum 5 character')
            .max(20, "Password can't be more then 20 characters"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: zodResolver(schema),
    });

    const handleSave = async () => {
        setBtnLoginDisabled(true);
        try {
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    username: user,
                    password: pwd,
                }),
            });

            if (response.status === 401) {
                setErrMsg(
                    'Login Failed - Please verify your account, check your email for the verification link'
                );
                setBtnLoginDisabled(false);
            } else if (response.status !== 200) {
                setErrMsg('Login Failed - Invalid Login Details');
                setBtnLoginDisabled(false);
            } else {
                setUser('');
                setPwd('');
                setCookie('user', await response.json(), { path: '/' });
                toast.success(`Welcome! ${user}`);
            }
        } catch (err) {
            setErrMsg('Login Failed');
            setBtnLoginDisabled(false);
        }
    };

    return (
        <>
            {showRegisterForm && <RegisterForm />}

            {!showRegisterForm && (
                <div className="card">
                    <p
                        ref={errRef}
                        className={errMsg ? 'errmsg' : 'offscreen'}
                        aria-live="assertive"
                    >
                        {errMsg}
                    </p>
                    <h2>Login</h2>
                    <form onSubmit={handleSubmit(handleSave)}>
                        {errors && errors.username && (
                            <span style={{ color: 'red' }}>
                                {errors.username.message}
                            </span>
                        )}

                        <input
                            {...register('username')}
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Username"
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                        />

                        <div style={{ position: 'relative' }}>
                            {errors && errors.password && (
                                <span style={{ color: 'red' }}>
                                    {errors.password.message}
                                </span>
                            )}
                            <input
                                {...register('password')}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                name="password"
                                placeholder="Password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                style={{ paddingRight: '4.2rem' }} // Add padding to accommodate the eye icon
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                style={{
                                    position: 'absolute',
                                    top: errors.password ? '55%' : '40%',
                                    right: '0.1rem',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: 'lightgray',
                                    border: 'none',
                                }}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>

                        <button disabled={btnLoginDisabled} type="submit">
                            Login
                        </button>

                        <p
                            style={{
                                color: 'white',
                                marginTop: '5px',
                                textAlign: 'center',
                            }}
                        >
                            Don't have an account
                            <Button
                                onClick={() =>
                                    setShowRegisterForm(!showRegisterForm)
                                }
                                style={{
                                    backgroundColor: 'grey',
                                    marginTop: '30px',
                                    marginLeft: '10px',
                                    textAlign: 'center',
                                }}
                            >
                                Sign Up
                            </Button>
                        </p>
                    </form>
                </div>
            )}
        </>
    );
};

export default Login;
