import { CookiesProvider, useCookies } from 'react-cookie';
import Login from './Componets/Login';
import Notes from './Componets/Notes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    const cookies: any = useCookies(['user']);
    return (
        <CookiesProvider>
            <div>
                {cookies[0].user ? (
                    <Notes user={cookies[0].user} />
                ) : (
                    <div className="container">
                        <Login />
                    </div>
                )}
            </div>
            <ToastContainer />
        </CookiesProvider>
    );
}

export default App;
