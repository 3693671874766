import React from 'react';
import { FaEdit } from 'react-icons/fa';
import { MdAutoDelete } from 'react-icons/md';
import { UserNote } from '../Models/UserNote';

interface NotesProps {
    note: UserNote;
    handleNoteClick: (note: UserNote) => void;
    handleConfirmDelete: (note: UserNote) => Promise<void>;
}

const Note: React.FC<NotesProps> = ({
    note,
    handleNoteClick,
    handleConfirmDelete,
}) => {
    return (
        <div className="sticky-note fold" key={note.id}>
            <div className="note-header">
                <FaEdit onClick={() => handleNoteClick(note)} />
                <MdAutoDelete onClick={() => handleConfirmDelete(note)} />
            </div>
            <div className="note-content">
                <h3>
                    {note.description.startsWith('http') ? (
                        <a href={note.description} target="_blank">
                            {
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: note.title,
                                    }}
                                />
                            }
                        </a>
                    ) : (
                        <>
                            <h4
                                dangerouslySetInnerHTML={{ __html: note.title }}
                            />

                            <div
                                dangerouslySetInnerHTML={{
                                    __html: note.description,
                                }}
                            />
                        </>
                    )}
                </h3>
            </div>
        </div>
    );
};

export default Note;
