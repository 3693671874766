import React from 'react';
import { Button, Dialog, DialogDismiss, DialogHeading } from '@ariakit/react';

interface ConfirmDialogProps {
    setDialogOpen: (isOpen: boolean) => void;
    openDialog: boolean;
    handler: () => Promise<void>;
    heading: string;
    body: string;
    handlerTextBtn: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    setDialogOpen,
    openDialog,
    handler,
    heading,
    body,
    handlerTextBtn,
}) => {
    return (
        <Dialog
            open={openDialog}
            onClose={() => setDialogOpen(false)}
            getPersistentElements={() => document.querySelectorAll('.Toastify')}
            backdrop={<div className="backdrop" />}
            className="dialog"
        >
            <DialogHeading className="heading">{heading}</DialogHeading>
            <p className="description">{body}</p>
            <div className="buttons">
                <Button className="button" onClick={handler}>
                    {handlerTextBtn}
                </Button>
                <DialogDismiss className="button secondary">
                    Cancel
                </DialogDismiss>
            </div>
        </Dialog>
    );
};

export default ConfirmDialog;
