import { UserNote } from '../Models/UserNote';
import Note from './Note';

interface NotesListProps {
    notes: UserNote[];
    handleNoteClick: (note: UserNote) => void;
    handleConfirmDelete: (note: UserNote) => Promise<void>;
}

const NotesList: React.FC<NotesListProps> = ({
    notes,
    handleNoteClick,
    handleConfirmDelete,
}) => {
    return (
        <div className="sticky-notes">
            {notes && notes?.length > 0 ? (
                notes.map((note) => (
                    <Note
                        key={note.id}
                        note={note}
                        handleNoteClick={handleNoteClick}
                        handleConfirmDelete={handleConfirmDelete}
                    />
                ))
            ) : (
                <p className="title">No notes found!</p>
            )}
        </div>
    );
};

export default NotesList;
