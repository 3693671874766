import React, { useEffect, useRef, useState } from 'react';
import { User } from '../Models/User';
import NotesList from './NotesList';
import { UserNote } from '../Models/UserNote';
import AddNote from './AddNote';
import Search from './Search';
import Header from './Header';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import Dialog from './ConfirmDialog';
import ConfirmDialog from './ConfirmDialog';

const Notes = (user: any) => {
    const [notes, setNotes] = useState<UserNote[]>([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [openDialog, setDialogOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [selectedNote, setSelectedNote] = useState<UserNote | null>(null);
    const [selectedDeleledNote, setSelectedDeleteNote] =
        useState<UserNote | null>(null);
    const userDetails: User = user?.user;
    const [cookies, setCookie] = useCookies(['user']);

    const { REACT_APP_NOTESAPI } = process.env;
    const url = `${REACT_APP_NOTESAPI}`;
    const token = `Bearer ${userDetails?.token}`;

    const fetchNotes = async () => {
        try {
            const headers = {
                Authorization: token,
            };

            const response = await fetch(
                `${url}?userId=${userDetails?.userId}`,
                {
                    headers,
                }
            );

            const notes: UserNote[] = await response.json();

            setNotes(notes);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchNotes();
    }, []);

    const handleNoteClick = (note: UserNote) => {
        setSelectedNote(note);
        setTitle(note.title);
        setContent(note.description);
    };

    const handleAddNote = async () => {
        if (!content || !title) return;

        setButtonDisabled(!isButtonDisabled);
        try {
            var response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                method: 'POST',
                body: JSON.stringify({
                    title,
                    description: content,
                    userId: userDetails?.userId,
                }),
            });

            if (response.status == 200) {
                fetchNotes();
                setContent('');
                setTitle('');
                toast.success('Note successfully added!');
                setButtonDisabled(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdateNote = async () => {
        if (!selectedNote) {
            return;
        }

        try {
            await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                method: 'POST',
                body: JSON.stringify({
                    id: selectedNote.id,
                    title,
                    description: content,
                    userId: userDetails?.userId,
                }),
            });

            fetchNotes();
            setContent('');
            setSelectedNote(null);
            setTitle('');
            toast.success('Note successfully updated!');
        } catch (e) {
            console.log(e);
        }
    };

    const handleConfirmDelete = async (note: UserNote) => {
        setDialogOpen(true);
        setSelectedDeleteNote(note);
    };

    const handleDeleteNote = async () => {
        setDialogOpen(false);
        let note = selectedDeleledNote!;
        await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            method: 'POST',
            body: JSON.stringify({
                id: note.id,
                title: note.title,
                description: note.description,
                userId: userDetails?.userId,
                isDeleted: true,
            }),
        });

        fetchNotes();
        toast.success('Note deleted!');
    };

    const handleCancel = () => {
        setTitle('');
        setContent('');
        setSelectedNote(null);
    };

    const handleSignOut = () => {
        setCookie('user', null, { path: '/' });
    };

    const renderConfirmDialog = () => {
        return (
            <ConfirmDialog
                setDialogOpen={setDialogOpen}
                openDialog={openDialog}
                handler={handleDeleteNote}
                heading="Confirmation!"
                body="Are you sure you want to delete this!"
                handlerTextBtn="Delete"
            />
        );
    };
    return (
        <>
            <Header handleSignOut={handleSignOut} userDetails={userDetails} />
            <Search handleSearchNote={setSearchText} />
            {renderConfirmDialog()}
            <div className="app-container">
                <AddNote
                    handleAddNote={handleAddNote}
                    handleUpdateNote={handleUpdateNote}
                    handleCancel={handleCancel}
                    selectedNote={selectedNote}
                    setTitle={setTitle}
                    setContent={setContent}
                    title={title}
                    content={content}
                    isButtonDisabled={isButtonDisabled}
                />
                <NotesList
                    notes={notes.filter((note) =>
                        note.title
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                    )}
                    handleNoteClick={handleNoteClick}
                    handleConfirmDelete={handleConfirmDelete}
                />
            </div>
        </>
    );
};

export default Notes;
