import { MdSearch } from 'react-icons/md';

interface SearchProps {
    handleSearchNote: (searchString: string) => void;
}

const Search: React.FC<SearchProps> = ({ handleSearchNote }) => {
    return (
        <div className="search">
            <input
                onChange={(event) => handleSearchNote(event.target.value)}
                type="text"
                placeholder="Type to search..."
            />
            <MdSearch className="search-icons" size="2em" />
        </div>
    );
};

export default Search;
