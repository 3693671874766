import React from 'react';
import { User } from '../Models/User';

interface HeaderProps {
    handleSignOut: () => void;
    userDetails: User;
}

const Header: React.FC<HeaderProps> = ({ handleSignOut, userDetails }) => {
    return (
        <div className="header">
            <h1 className="title">
                My Notes - Welcome {userDetails?.userName}
            </h1>
            <button className="save" onClick={handleSignOut}>
                Sign Out
            </button>
        </div>
    );
};

export default Header;
